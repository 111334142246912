<template>
  <div class="w-full pt-5 pb-5">
    <div v-if="hasAccessModule">
      <div class="flex">
        <back-button
          label="Back"
          @onClick="
            $router.push({
              name: 'OrganisationlGoal',
              query: { activeTab: 'Long Term Goals' },
            })
          "
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878e99; height: 1.5rem"
        />

        <div style="width: 150px">
          <h1 class="text-xl text-left font-extrabold ml-4 mt-3">Edit Goal</h1>
        </div>

        <div class="w-7/12 mt-4">
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>

      <div style="height: 100%" class="mt-20" v-if="loading">
        <loader size="xxs" :loader-image="false" />
      </div>

      <div v-else>
        <ValidationObserver rules="required" v-slot="{ handleSubmit }">
          <div class="px-3">
            <card class="p-5 mt-3">
              <p class="text-lg font-bold">Goal Details</p>
              <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
                <div class="col-span-5">
                  <div class="text-sm text-jet">Financial Year</div>
                  <c-text
                    placeholder="--Enter--"
                    variant="w-full"
                    class="label mt-1"
                    :disabled="true"
                    v-model="payload.period"
                  />
                </div>

                <div class="col-span-7">
                  <c-text
                    placeholder="--Enter--"
                    variant="w-full"
                    class="label mt-1"
                    v-model="payload.description"
                    label="Description"
                    :rules="['required']"
                  />
                </div>
              </div>
            </card>
          </div>

          <div class="px-3">
            <card class="p-5" style="margin-top: 4px">
              <div
                class="w-full flex flex-row"
                style="margin-top: 20px; height: 44px; flex-grow: 1"
              >
                <div class="w-7/12 h-auto mt-2">
                  <h1 class="text-base font-bold">KPAs</h1>
                </div>

                <div class="h-full w-5/12 justify-end flex flex-row">
                  <div class="h-full w-auto flex flex-row">
                    <div class="p-2 mx-3 bg-ghostWhite h-full flex">
                      <span class="text-sm font-black flex flex-grow">
                        Total Allocated Weight:
                      </span>
                      <span class="text-sm text-desire ml-3">
                        {{ allocatedWeight }}%
                      </span>
                      <icon :icon-name="iconInfo" size="ksm" />
                    </div>
                  </div>

                  <div
                    class="p-2 h-full flex flex-row cursor-pointer"
                    @click="kpaModal = true"
                  >
                    <icon
                      :icon-name="iconPlus"
                      size="ksm"
                      style="margin-right: 5px"
                    />
                    <span class="text-base font-semibold text-flame">
                      Add KPA Type
                    </span>
                  </div>
                </div>
              </div>

              <div style="border-bottom: 1px solid #878e99; height: 1rem" />

              <div class="flex flex-col w-full my-4">
                <!----- List of KPA's ---->

                <div class="w-full h-auto flex flex-col">
                  <accordion
                    class="mt-6"
                    v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                    :key="kpaNumber"
                    show-divider
                    @open="handleOpen($event, kpaTypes.id)"
                    :is-open="true"
                  >
                    <template v-slot:title>
                      <span class="font-bold">{{ kpaTypes.name }}</span>
                    </template>

                    <template v-slot:editIcon>
                      <div
                        class="flex ml-4 text-blueCrayola cursor-pointer"
                        @click="weightAdjust = true"
                      >
                        <icon icon-name="edit" size="xs" />
                      </div>
                    </template>

                    <template v-slot:block>
                      <div
                        class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                      >
                        <span
                          class="font-black text-xs"
                          :class="
                            getKPAWeight(kpaTypes, kpaTypes.id) >
                            getTotalWeight(kpaTypes, kpaTypes.id)
                              ? 'text-desire'
                              : ''
                          "
                        >
                          {{ getKPAWeight(kpaTypes, kpaTypes.id) }}
                        </span>
                        <span class="font-black text-xs"> of </span>
                        <span class="font-black text-xs">
                          {{ getTotalWeight(kpaTypes, kpaTypes.id) }}%
                        </span>
                      </div>
                    </template>

                    <div class="w-full">
                      <ValidationObserver v-slot="{ handleSubmit }">
                        <div class="w-full flex justify-end">
                          <div
                            class="text-flame font-semibold flex mb-2 p-2 mr-3 text-base cursor-pointer"
                            @click="handleSubmit(createNewKPA(kpaTypes))"
                          >
                            <icon
                              icon-name="icon-plus"
                              class="mr-2"
                              size="xsm"
                            />
                            Add New KPI
                          </div>
                        </div>

                        <scroll-container height="1000px" style="width: 99%">
                          <Table
                            :headers="KPAHeader"
                            :items="kpaTypes.kpas"
                            class="h-full my-2 table"
                            aria-label="absence table"
                            :has-number="false"
                          >
                            <template v-slot:item="{ item }">
                              <div v-if="item.state" class="-ml-3">
                                <div v-if="item.data.state" class="p-2">
                                  <div class="flex flex-col">
                                    <div
                                      class="cursor-pointer"
                                      v-if="item.data.isLocked"
                                      @click="item.data.isLocked = false"
                                    >
                                      <icon
                                        icon-name="icon-lock"
                                        size="xs"
                                        class="my-2"
                                      />
                                    </div>
                                    <div
                                      class="cursor-pointer"
                                      v-else
                                      @click="item.data.isLocked = true"
                                    >
                                      <icon
                                        icon-name="icon-unlock"
                                        size="xs"
                                        class="my-2"
                                      />
                                    </div>
                                    <div
                                      class="cursor-pointer"
                                      @click="deleteKPA(item.index, kpaTypes)"
                                      v-if="!item.data.isLocked"
                                    >
                                      <icon
                                        icon-name="icon-trash"
                                        size="xs"
                                        class="my-2"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div v-else-if="item.name" class="kpas-err">
                                  <c-text
                                    placeholder="--Enter--"
                                    variant="w-full"
                                    class="mr-2 -ml-1 -mt-1 w-72"
                                    :disabled="item.data.isLocked"
                                    v-model="item.data.name"
                                    v-tooltip.bottom-middle="item.data.name"
                                    :rules="['required']"
                                    label="KPI"
                                  />
                              </div>

                              <div v-else-if="item.objective" class="kpas-err">
                                <div class="label">
                                  <c-text
                                    placeholder="--Description--"
                                    variant="w-full"
                                    class="mr-2 -ml-1 -mt-2 w-72"
                                    :disabled="item.data.isLocked"
                                    v-model="item.data.objective"
                                    v-tooltip.bottom-middle="
                                      item.data.objective
                                    "
                                    :rules="['required']"
                                    label="Objective"
                                  />
                                </div>
                              </div>

                              <div v-else-if="item.kpis" class="flex kpas-err">
                                <div
                                  class="label flex"
                                  v-for="(n, rating) in item.data.kpis"
                                  :key="rating"
                                >
                                  <ValidationObserver>
                                    <c-text
                                      variant="w-full"
                                      :class="`mr-2 -mt-2 w-48`"
                                      :placeholder="`Criteria ${
                                        lowest_rating + rating
                                      }`"
                                      :disabled="item.data.isLocked"
                                      v-model="item.data.kpis[rating].criteria"
                                      v-tooltip.bottom-middle="
                                        item.data.kpis[rating].criteria
                                      "
                                      :rules="['required']"
                                      :label="`Criteria ${rating} value`"
                                    />
                                  </ValidationObserver>
                                </div>
                              </div>
                              <div
                                v-else-if="item.target_date"
                                class="kpas-err"
                              >
                                <div class="label w-72 -mt-2 -ml-1">
                                  <div
                                    class="date-flex"
                                    :class="
                                      item.data.isLocked ? 'bg-disabled' : ''
                                    "
                                  >
                                    <datepicker
                                      placeholder="--Select Day & Month & Year--"
                                      style="width: 100%; outline: none"
                                      input-class="date-input"
                                      v-model="item.data.target_date"
                                      :disabled="item.data.isLocked"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div v-else-if="item.weight" class="kpas-err">
                                <div class="label">
                                  <c-text
                                    placeholder="--Enter Weight--"
                                    variant="w-full"
                                    class="mr-2 -ml-1 -mt-2 w-24"
                                    type="number"
                                    min="0"
                                    max="100"
                                    :rules="weightRules"
                                    v-model="item.data.weight"
                                    :disabled="item.data.isLocked"
                                    label="Weight"
                                  />
                                </div>
                              </div>
                            </template>
                          </Table>
                        </scroll-container>
                      </ValidationObserver>
                    </div>
                  </accordion>
                </div>
              </div>

              <div class="w-full flex p-1 mt-2">
                <radio-button
                  :options="optionBox"
                  col-span="col-span-4"
                  class="inline-block text-darkPurple mr-4 gap-x-12 radio"
                  @change="getSelected($event)"
                  v-model="payload.shareWith"
                />
              </div>
            </card>
          </div>

          <div class="pl-3 flex mt-5">
            <div class="flex flex-grow">
              <Button
                :class="disableBtn && `btn-disabled`"
                :background="appearance.buttonColor || defaultBTNColor"
                :style="disableBtn ? `color: #878E99` : `color: #FFFFFF`"
                width="8rem"
                :disabled="disableBtn"
                @click="handleSubmit(submit)"
              >
                Save
              </Button>
              <Button
                background-color="none"
                class="ml-4"
                style="color: #321c3b"
                @click="
                  $router.push({
                    name: 'OrganisationlGoal',
                    query: { activeTab: 'Long Term Goals' },
                  })
                "
              >
                Cancel
              </Button>
            </div>
            <div class="flex justify-end mr-5">
              <Button
                class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
                width="8rem"
                @click="submit('draft')"
                v-if="payload.status === 'draft'"
              >
                Save Draft
              </Button>
            </div>
          </div>

          <ValidationObserver v-slot="{ handleSubmit }">
            <RightSideBar
              v-if="kpaModal"
              @close="kpaModal = false"
              close-button
              button-class="bg-dynamicBackBtn text-white"
            >
              <template v-slot:title>
                <p class="text-darkPurple">KPA Types</p>
              </template>
              <template v-slot:subtitle>
                <p class="font-semibold text-darkPurple my-4 text-base">
                  Add KPA types used in your long term organisation goal
                </p>
              </template>
              <div class="flex flex-col font-semibold my-4 text-base">
                KPA Types
              </div>
              <div
                class="flex w-full my-4 label"
                v-for="(k, index) in orgkpaTypes"
                :key="index"
              >
                <c-text
                  v-model="k.name"
                  placeholder="Enter KPA Type"
                  class="w-11/12"
                  :rules="['required']"
                  label="Kpa Name"
                  :disabled="k.saved"
                />
                <div class="flex w-1/12 h-10">
                  <div
                    v-if="k.saved"
                    class="cursor-pointer flex"
                    @click="openEdit(k)"
                  >
                    <icon
                      icon-name="icon-edit"
                      size="xms"
                      class="mt-1 mx-4 text-blueCrayola"
                    />
                  </div>
                  <div
                    class="flex cursor-pointer"
                    v-else
                    @click="
                      k.edit
                        ? handleSubmit(editKPA(k))
                        : handleSubmit(saveKPA(k))
                    "
                  >
                    <icon
                      icon-name="save"
                      class="mt-1 mx-4 text-blueCrayola"
                      size="xms"
                      style="width: 0.8rem"
                    />
                  </div>
                  <div
                    class="flex cursor-pointer"
                    @click="deleteKPAList(k, index)"
                  >
                    <icon icon-name="icon-trash" class="mt-1" size="xs" />
                  </div>
                </div>
              </div>
              <div
                class="text-flame font-semibold flex w-full my-4 p-2 text-base cursor-pointer"
                @click="createKPA"
              >
                <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                Add KPA Type
              </div>
            </RightSideBar>
          </ValidationObserver>

          <RightSideBar
            v-if="weightAdjust"
            @close="weightAdjust = false"
            submit="Save"
            @submit="saveWeight"
            :button-class="`bg-dynamicBackBtn text-white`"
          >
            <template v-slot:title>
              <div class="w-full flex">
                <p class="text-darkPurple mt-3">Edit Weight</p>
              </div>
            </template>
            <template v-slot:subtitle>
              <div class="flex flex-col w-full mb-3">
                <p class="my-2 text-base">Edit KPA Weights</p>
              </div>
            </template>

            <div class="w-full flex flex-col">
              <div class="w-full flex p-4 mb-1">
                <span
                  class="text-base text-darkPurple flex flex-grow mt-2 font-semibold"
                >
                  Total Weight
                </span>
                <c-text
                  placeholder="100"
                  variant="w-full"
                  class="-ml-1 -mt-2 w-20"
                  :disabled="true"
                  v-model="totalWeight"
                />
              </div>

              <div class="w-full flex flex-col">
                <div
                  class="w-full flex p-4 mb-1"
                  v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                  :key="kpaNumber"
                >
                  <span class="text-base text-darkPurple flex flex-grow mt-2">
                    {{ kpaTypes.name }}
                  </span>
                  <div class="flex flex-col w-20 mr-2 -ml-1">
                    <span class="flex flex-col relative">
                      <span
                        class="w-full"
                        style="
                          height: 40px;
                          padding: 10px 15px;
                          width: 100%;
                          border-radius: 5px;
                          color: #878e99;
                          background-color: #eeeeee;
                          line-height: 120%;
                          letter-spacing: 0.015em;
                          text-align: center;
                          font-weight: 600;
                        "
                        :class="
                          kpaTypes.weight > kpaTypes.totalWeight
                            ? `text_desire`
                            : ``
                        "
                      >
                        {{ kpaTypes.weight }}
                      </span>
                    </span>
                  </div>
                  <span class="text-base text-darkPurple flex mt-2 mr-2">
                    of
                  </span>
                  <c-text
                    placeholder=""
                    variant="w-full"
                    class="-mt-2 w-20"
                    type="number"
                    v-model="kpaTypes.totalWeight"
                    @input="computeKPAWeights"
                  />
                </div>
              </div>
            </div>
          </RightSideBar>

          <RightSideBar
            v-if="addOthersModal"
            @close="addOthersModal = false"
            submit="Proceed"
            @submit="employeeSubmit"
            :button-class="`bg-dynamicBackBtn text-white`"
          >
            <template v-slot:title>
              <div class="w-full flex">
                <p class="text-darkPurple mt-3">Add Others</p>
              </div>
            </template>
            <template v-slot:subtitle>
              <div class="flex flex-col w-full mb-3">
                <p class="my-2 text-base">Search existing employees to add.</p>
              </div>
            </template>

            <search-input
              class="my-4"
              @input="startSearch"
              placeholder="Search"
            />

            <div style="height: 100%" v-if="loadingBar">
              <loader size="xxs" :loader-image="false" />
            </div>
            <div v-else>
              <div
                v-for="(item, index) in employees"
                :key="index"
                class="flex flex-col py-4 userContainer border-b border-dashed"
              >
                <div class="flex items-center">
                  <div>
                    <img
                      class="mr-3"
                      :src="item.photo"
                      v-if="item.photo"
                      alt="user photo"
                      style="height: 35px; width: 35px; border-radius: 5px"
                    />
                    <div
                      style="height: 35px; width: 35px; border-radius: 5px"
                      class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                      v-else
                    >
                      {{ $getInitials(`${item.fname} ${item.lname}`) }}
                    </div>
                  </div>
                  <div class="flex flex-col flex-grow">
                    <p>
                      {{ `${item.fname} ${item.lname}` }}
                    </p>
                    <div
                      class="text-xs flex font-semibold w- text-romanSilver uppercase"
                    >
                      <p class="opacity-50 mr-2" style="font-size: 10px">
                        {{
                          `${item.orgFunction ? item.orgFunction.name : "---"}`
                        }}
                      </p>
                      <p class="opacity-50 ml-1" style="font-size: 10px">
                        <span
                          class="dot dot-circle secondary"
                          style="width: 6px; height: 6px"
                        />
                      </p>
                      <p class="opacity-50 ml-2" style="font-size: 10px">
                        {{
                          `${
                            item.userDesignation
                              ? item.userDesignation.name
                              : "---"
                          }`
                        }}
                      </p>
                    </div>
                  </div>
                  <checkbox
                    checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
                    label-style="color:#333333; "
                    class="mr-6"
                    :value="[item]"
                    v-model="item.selected"
                  />
                </div>
              </div>
              <p
                class="text-flame ml-4 mt-10 cursor-pointer"
                @click="getAllEmployees(`&page=${pageNo}`)"
              >
                {{ showMore ? "Show Less" : "Show More" }}
              </p>
            </div>
          </RightSideBar>
        </ValidationObserver>
      </div>
    </div>
    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Datepicker from "vuejs-datepicker";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import RightSideBar from "@/components/RightSideBar";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";
import Accordion from "@/components/Accordion";
import RadioButton from "@/components/RadioButton";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  components: {
    ValidationObserver,
    Datepicker,
    BackButton,
    ErrorComponent,
    Breadcrumb,
    Icon,
    CText,
    Card,
    Button,
    Checkbox,
    RightSideBar,
    SearchInput,
    ScrollContainer,
    Accordion,
    Table,
    RadioButton,
  },
  data() {
    return {
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Organisational Goals and Objectives",
          href: "Goals",
          id: "Goals",
        },
        {
          disabled: false,
          text: "Edit Goal",
          id: "Edit goal",
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPA", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      KPAData: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      optionBox: [
        { name: "Share with CEO", radioName: "share", value: "ceo" },
        {
          name: "Share with CEO & Direct Reports",
          radioName: "share",
          value: "ceo_and_direct_reports",
        },
        {
          name: "Share with CEO & Others",
          radioName: "share",
          value: "ceo_and_others",
        },
      ],
      illustration: "illustration",
      allocatedWeight: 0,
      templateKPATypes: [],
      kpaModal: false,
      loadingBar: true,
      weightAdjust: false,
      loading: true,
      showMore: false,
      pageNo: "",
      employees: [],
      orgkpaTypes: [],
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      edit: false,
      addOthersModal: false,
      previousGoalModal: false,
      maxWeight: false,
      selectedEmployee: "",
      Ratings: [],
      criteriaNumber: "",
      templateOptions: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      appraisal_cycles: [],
      goalKPATypes: [],
      kpaData: [],
      oldKPAs: [],
      hasAccessModule: false,
      payload: {
        orgId: "",
        description: "",
        period: "",
        status: "draft",
        kpa_types: [],
        shareWith: "",
        sharedEmployees: [],
      },
    };
  },
  computed:{
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },
  methods: {
    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.templateKPATypes.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    computeKPAWeights() {
      let sum = 0;

      this.templateKPATypes.forEach((kpa) => {
        sum += Number(kpa.totalWeight);
      });

      this.totalWeight = sum;
    },

    getTotalWeight(kpaTypes, kpaTypesId) {
      let sum = 0;
      this.templateKPATypes.filter((kpa) => {
        if (kpa.id === kpaTypesId) {
          sum = kpa.totalWeight;
        }
        return {};
      });

      return sum;
    },

    getSelected(value) {
      if (value === "ceo_and_others") {
        this.addOthersModal = true;
      }
    },

    validateWeight(weight) {
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      return true;
    },

    watchTotalWeight() {
      let sum = 0;

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      if (sum < 100) {
        this.maxWeight = false;
        this.disableBtn = true;
      } else {
        this.maxWeight = true;
        this.disableBtn = false;
      }

      this.allocatedWeight = sum;
    },

    startSearch(value) {
      this.employees = [];
      if (value !== "") {
        const searchQuery = `&search=${value}`;
        this.loadingBar = true;
        this.employeeSearch = true;
        this.getEmployeesSearch(searchQuery);
      } else {
        this.employeeSearch = false;
        this.getAllEmployees("");
      }
    },

    employeeSubmit() {
      const employeeSelected = this.employees.some(
        (employee) => employee.selected === true
      );

      if (employeeSelected) {
        this.addOthersModal = false;
        this.$toasted.success("Employees Selected", { duration: 5000 });
      } else {
        this.$toasted.error("Select one or more employees to proceed", {
          duration: 5000,
        });
      }
      return {};
    },

    submit(status) {
        this.kpaData = [];
        let isSatisfied = true;

        this.templateKPATypes.forEach((kpaType) => {
          const newKPAs = kpaType.kpas.map(({ state, ...rest }) => ({
            ...rest,
          }));

          newKPAs.forEach((kpa) => {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = Number(kpa.weight);
          });

          if (!status) {
            const validation = newKPAs.every(
              (item) => item.name || item.objective
            );

            if (validation) {
              this.kpaData.push({
                kpa_types_id: kpaType.id,
                kpas: newKPAs,
              });
            } else {
              this.kpaData.push({
                kpa_types_id: kpaType.id,
                kpas: [],
              });
            }
          } else {
            this.kpaData.push({
              kpa_types_id: kpaType.id,
              kpas: newKPAs,
            });
          }

          return {};
        });

        this.payload.kpa_types = this.kpaData;
        this.payload.orgId = this.$orgId;
        this.payload.sharedEmployees = [];

        this.employees.filter((employee) => {
          if (employee.selected) {
            this.payload.sharedEmployees.push(employee.id);
          }
          return {};
        });

        this.payload.id = this.$route.params.id;

        this.templateKPATypes.map((kpaWeight) => {
          if (kpaWeight.weight > kpaWeight.totalWeight) {
            isSatisfied = false;
          }
          return {};
        });

        if (!status) {
          if (isSatisfied) {
            if (this.allocatedWeight === 100) {
              this.payload.status = "active";
              this.$_editLongTermGoal(this.payload)
                .then((response) => {
                  this.$toasted.success(response.data.message, {
                    duration: 5000,
                  });
                  this.$router.push({
                    name: "OrganisationlGoal",
                    query: { activeTab: "Long Term Goals" },
                  });
                })
                .catch((error) => {
                  this.$toasted.error(error.response.data.message, {
                    duration: 5000,
                  });
                });
            } else {
              this.$toasted.error("Total Weight must be 100%", {
                duration: 5000,
              });
            }
          } else {
            this.$toasted.error(
              "Allocated weights of some KPA Types is greater than their set weight",
              {
                duration: 5000,
              }
            );
          }
        } else {
          this.$_editLongTermGoal(this.payload)
            .then((response) => {
              this.$toasted.success(response.data.message, {
                duration: 5000,
              });
              this.$router.push({
                name: "OrganisationlGoal",
                query: { activeTab: "Long Term Goals" },
              });
            })
            .catch((error) => {
              this.$toasted.error(error.response.data.message, {
                duration: 5000,
              });
            });
        }
    },

    deleteKPA(index, kpaTypes) {
      if (kpaTypes.kpas.length > 1) {
        kpaTypes.kpas.splice(index, 1);
      } else {
        this.$toasted.error(
          "Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead",
          { duration: 5000 }
        );
      }
    },

    handleOpen(state) {
      if (state) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },

    createNewKPA(kpa) {
      const arrayObj = kpa.kpas[kpa.kpas.length - 1];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.kpis.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                kpis: this.getCriteriaData(),
                target_date: null,
                weight: "",
              });
            }
            return {};
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%. To add new KPI, reduce any existing KPA or KPI weight(s) ",
            { duration: 5000 }
          );
        }
      }
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate("").then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.template_type !== "draft") {
            this.templateOptions.push({
              id: template.id,
              name: template.title,
            });
          }
          return {};
        });

        this.loading = false;
      });
    },

    getEmployeesSearch(searchQuery) {
      this.$_getAllEmployeesWithQueries(searchQuery).then((response) => {
        this.loadingBar = false;
        this.employees = response.data.data.employees;
      });
    },

    getAllEmployees(query) {
      this.loadingBar = true;
      this.$_getAllEmployeesWithQueries(query).then((response) => {
        this.loadingBar = false;
        response.data.data.employees.map((employee) => {
          this.employees.push({
            id: employee.id,
            fname: employee.fname,
            lname: employee.lname,
            orgFunction: employee.orgFunction,
            userDesignation: employee.userDesignation,
            photo: employee.photo,
            selected: false,
          });
          return {};
        });
        this.pageNo = response.data.data.meta.nextPage;
      });
    },

    getOneGoal() {
      this.$_getOneLongTermGoal(this.$route.params.id).then((response) => {
        const goalData = response.data.goal;
        this.loading = false;
        let longTermKPA = [];
        this.templateKPATypes = [];

        this.payload.description = goalData.description;
        longTermKPA = goalData.long_term_kpaType;
        this.payload.shareWith = goalData.shareWith;
        this.payload.sharedEmployees = goalData.sharedEmployees;
        this.payload.period = goalData.period;
        this.payload.status = goalData.status;

        this.timer = setTimeout(() => {
          goalData.sharedEmployees.map((data) => {
            this.employees.filter((employee) => {
              const employeeData = employee;

              if (employee.id === data) {
                employeeData.selected = true;
              }
              return {};
            });
            return {};
          });
        }, 2000);

        let totalSum = 0;

        if (longTermKPA.length > 0) {
          longTermKPA.forEach((kpaType) => {
            let sum = 0;

            totalSum += kpaType.weight;

            this.totalWeight = totalSum;

            kpaType.kpa_type.long_term_kpas.map((kpi) => {
              sum += kpi.weight;
              return {};
            });

            this.oldKPAs.push(kpaType.kpa_type.name);

            this.templateKPATypes.push({
              id: kpaType.kpa_type.id,
              name: kpaType.kpa_type.name,
              kpas: this.setKPA(kpaType.kpa_type.long_term_kpas),
              totalWeight: kpaType.weight,
              weight: sum,
            });
          });
        }
        return {};
      });
    },

    setKPA(kpas) {
      const newArray = [];

      if (kpas.length > 0) {
        kpas.forEach((kpa) => {
          newArray.push({
            state: true,
            isLocked: kpa.isLocked,
            name: kpa.name,
            objective: kpa.objective,
            kpis: this.getCriteriaData(kpa.kpi),
            target_date: kpa.target_date,
            weight: kpa.weight,
          });
        });
      } else {
        newArray.push({
          state: true,
          isLocked: false,
          name: null,
          objective: null,
          kpis: this.getCriteriaData(),
          target_date: null,
          weight: "",
        });
      }
      return newArray;
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      this.lowest_rating = Math.min.apply(
        this.Ratings,
        this.Ratings.map((item) => item.rating)
      );

      this.Ratings.map((rating, index) => {
        criteriaData.push({
          performanceRatingId: rating.id,
          criteria: kpis ? kpis[index].criteria : "",
        });
        return {};
      });

      setTimeout(() => {
        this.loading = false;
      }, 2000);

      return criteriaData;
    },

    saveWeight() {
      const payload = { kpa_types: [] };

      this.templateKPATypes.map((kpaWeight) => {
        payload.kpa_types.push({
          id: kpaWeight.id,
          weight: Number(kpaWeight.totalWeight),
        });
        return {};
      });

      if (this.totalWeight === 100) {
        this.$_setLongTermKPAWeight(payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.getKPATypes();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 5000 });
          });
      } else {
        this.$toasted.error("Total KPA Weight must be equal to 100%", {
          duration: 5000,
        });
      }
    },

    createKPA() {
      this.orgkpaTypes.push({
        id: null,
        name: "",
        orgId: this.$orgId,
        saved: false,
        edit: false,
      });
    },

    normalizeKPA(kpaTypes) {
      this.orgkpaTypes = [];

      let totalSum = 0;

      kpaTypes.filter((kpa) => {
        let sum = 0;

        totalSum += kpa.weight;

        this.totalWeight = totalSum;

        kpa.long_term_kpas.map((kpi) => {
          sum += kpi.weight;
          return {};
        });

        this.orgkpaTypes.push({
          id: kpa.id,
          orgId: kpa.orgId,
          name: kpa.name,
          totalWeight: kpa.weight,
          weight: sum,
          saved: true,
          edit: true,
        });

        return {};
      });
    },

    attachNewKPA() {
      this.goalKPATypes.filter((kpa) => {
        if (!this.oldKPAs.includes(kpa.name)) {
          this.templateKPATypes.push({
            id: kpa.id,
            name: kpa.name,
            kpas: [
              {
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                kpis: this.getCriteriaData(),
                target_date: null,
                weight: "",
              },
            ],
            totalWeight: kpa.weight,
            weight: kpa.weight,
          });

          this.oldKPAs.push(kpa.name);
        }
        return {};
      });
    },

    deleteKPAList(kpa, index) {
      if (kpa.id) {
        this.orgkpaTypes.splice(index, 1);
        this.$_deleteKPAListtypes(kpa.id).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getKPATypes();
        });
      }
    },

    saveKPA(kpa) {
      let payload = {};
      const indexedKPA = kpa;

      payload = {
        orgId: kpa.orgId,
        name: kpa.name,
      };

      indexedKPA.saved = true;

      this.$_createLongTermKPA(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getKPATypes();
          setTimeout(() => {
            this.attachNewKPA();
          }, 2000);
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
          indexedKPA.saved = false;
        });
    },

    openEdit(kpa) {
      const indexedKPA = kpa;
      indexedKPA.saved = false;
      indexedKPA.edit = true;
    },

    editKPA(kpa) {
      let payload = {};
      const indexedKPA = kpa;

      payload = {
        id: kpa.id,
        name: kpa.name,
      };

      indexedKPA.saved = true;

      this.$_editLongTermKPA(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getKPATypes();
          // this.getOneGoal();
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 5000 });
          indexedKPA.saved = false;
        });
    },

    getKPATypes() {
      this.$_getLongTermKPA().then((response) => {
        const kpaTypes = response.data.KpaType;
        this.goalKPATypes = kpaTypes;
        this.normalizeKPA(kpaTypes);
      });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "organisationalGoals",
        "viewLongTermOrganisationalGoals"
      );
      this.hasAccessModule = true;
      this.$_getPerfomanceRating().then((response) => {
        this.Ratings = response.data.PerformanceRatings;
        this.getOneGoal();
        this.getKPATypes();
      });
      this.getAllEmployees("");
      setInterval(() => {
        this.watchTotalWeight();
      }, 1000);
    } catch (error) {
      this.hasAccessModule = false;
    }
  },
};
</script>

<style>
.kpas-err small {
  margin-bottom: -1.4rem;
}

.text_desire {
  color: rgba(234, 60, 83, 1) !important;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.label label {
  display: block;
  font-size: 10px;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}
</style>
